import EFilterRendererType from '@/typings/filters/types/EFilterRendererType';
import store from '@/store';
import ActivityGroupsStoreActions from '@/constants/store/modules/activityGroups/actions';
import GymPlansStoreGetters from '@/constants/store/modules/gymPlans/getters';
import FilterRow from '@/typings/filters/layout/FilterRow';
import GymAttendeeStatus from '@/constants/gymAttendee/GymAttendeeStatus';

const rows: FilterRow[] = [
  {
    props: {
      align: 'end',
    },
    columns: [
      {
        props: {
          sm: '12',
          cols: 'sm',
          md: '4',
        },
        filters: [
          {
            name: 'activityGroups',
            data: [],
            label: 'Activity Group',
            componentOptions: {
              clearable: true,
              dense: true,
              placeholder: 'Select',
              color: '#ffff',
              'background-color': '#fffff',
              items: store.getters[`activityGroups/${ActivityGroupsStoreActions.GET_ACTIVITY_GROUPS}`],
              'item-text': 'name',
              'item-value': 'id',
              'return-object': true,
              multiple: true,
            },
            type: EFilterRendererType.Select,
          },
        ],
      },
      {
        props: {
          sm: '12',
          cols: 'sm',
          md: '2',
        },
        filters: [
          {
            name: 'plans',
            data: [],
            label: 'Plans',
            componentOptions: {
              clearable: true,
              dense: true,
              color: '#ffff',
              placeholder: 'Select',
              'background-color': '#fffff',
              items: store.getters[`gymPlans/${GymPlansStoreGetters.GET_GYM_PLANS}`],
              'item-text': 'name',
              'item-value': 'id',
              'return-object': true,
              multiple: true,
            },
            type: EFilterRendererType.Select,
          },
        ],
      },
      {
        props: {
          sm: '12',
          cols: 'sm',
          md: '2',
        },
        filters: [
          {
            name: 'name',
            data: [],
            label: 'Name',
            componentOptions: {
              placeholder: 'Enter',
              dense: true,
              color: '#ffff',
              'background-color': '#fffff',
            },
            type: EFilterRendererType.Input,
          },
        ],
      },
      {
        props: {
          sm: '12',
          cols: 'sm',
          md: '2',
        },
        filters: [
          {
            name: 'status',
            data: [],
            label: 'Status',
            componentOptions: {
              placeholder: 'Select',
              clearable: true,
              dense: true,
              color: '#ffff',
              'background-color': '#fffff',
              items: [
                { text: 'Active', value: GymAttendeeStatus.Active },
                { text: 'Inactive', value: GymAttendeeStatus.Inactive },
                { text: 'Paused', value: GymAttendeeStatus.Paused },
              ],
              'item-text': 'text',
              'item-value': 'value',
              'return-object': true,
              multiple: false,
            },
            type: EFilterRendererType.Select,
          },
        ],
      },
      {
        props: {
          sm: '12',
          cols: 'sm',
          md: '2',
        },
        filters: [
          {
            name: 'age',
            data: [],
            label: 'Age',
            componentOptions: {
              from: {
                placeholder: 'From',
                dense: true,
                color: '#ffff',
                'background-color': '#fffff',
              },
              to: {
                placeholder: 'To',
                dense: true,
                color: '#ffff',
                'background-color': '#fffff',
              },
            },
            type: EFilterRendererType.NumberRange,
          },
        ],
      },
    ],
  },
];

export default rows;
